<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="open">
        <chat />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    chat () { return import('@/components/chat/interessentChat') }
  },
  props: {},
  data () {
    return {
      open: false
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
